import React, { useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import { useSetOrder } from './src/atoms/order.atom'
import { useLocalStorage } from '@uidotdev/usehooks'
import { orderDefaultValues } from './src/atoms/order.atom'

import "@fontsource/lato/100.css"; // Weight 400
import "@fontsource/lato/300.css"; // Weight 400
import "@fontsource/lato/400.css"; // Weight 400

import "@fontsource/assistant/200.css"; // Weight 400
import "@fontsource/assistant/300.css"; // Weight 400
import "@fontsource/assistant/400.css"; // Weight 400
import "@fontsource/assistant/500.css"; // Weight 400
import "@fontsource/assistant/600.css"; // Weight 400

import "@fontsource/alex-brush/400.css"; // Weight 400

import "@fontsource/exo-2/100.css"; // Weight 400
import "@fontsource/exo-2/200.css"; // Weight 400
import "@fontsource/exo-2/300.css"; // Weight 400
import "@fontsource/exo-2/400.css"; // Weight 400
import "@fontsource/exo-2/500.css"; // Weight 400
import "@fontsource/exo-2/600.css"; // Weight 400

import './src/styles/index.css'
import 'react-tooltip/dist/react-tooltip.css'

export const wrapRootElement = ({ element, props }) => {
  return <RecoilRoot {...props}>{element}</RecoilRoot>
}

export const wrapPageElement = ({ element, props }) => {
  const setOrder = useSetOrder()
  const [, setSponsorshipId] = useLocalStorage<string | null>(
    'sponsorshipId',
    null
  )

  useEffect(() => {
    if (location.pathname !== '/order') {
      setOrder((prevV) => {
        return {
          ...orderDefaultValues,
          packageId: prevV.packageId,
        }
      })
    }

    if (location.pathname === '/') {
      document.body.style.backgroundColor = '#F8FAFF'
      const sponsorshipId = new URLSearchParams(props.location.search).get(
        'sponsorshipId'
      )

      if (sponsorshipId) {
        setSponsorshipId(sponsorshipId)
      }
    } else if (location.pathname.match('/recrutement')) {
      document.body.style.backgroundColor = '#F8FAFF'
    } else {
      document.body.style.backgroundColor = '#FFF'
    }
  }, [props.location])
  return element
}
