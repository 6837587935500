import { AtomsKeys } from '../types/recoil'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

export type Order = {
  packageId: null | string
  sub: null | string
  options: string[]
  calendlyUri: null | string
  backgrounds: string[]
  allowShareImages: boolean
  discountCode: {
    value: null | string
    label: string
    amount: number
  }
}

export const orderDefaultValues: Order = {
  packageId: null,
  sub: null,
  options: [],
  calendlyUri: null,
  backgrounds: [],
  allowShareImages: false,
  discountCode: {
    label: '',
    value: null,
    amount: 0,
  },
}

const orderAtom = atom<Order>({
  key: AtomsKeys.ORDER,
  default: orderDefaultValues,
})

export const useOrder = () => useRecoilState(orderAtom)
export const useOrderValue = () => useRecoilValue(orderAtom)
export const useSetOrder = () => useSetRecoilState(orderAtom)
